import { fetchAuthenticatedContent } from "@parallelpublicworks/entitysync";

export default async function submitTaskFile(
  answerId,
  field_name,
  file,
  userContext,
  filename
) {
  const userState =
    userContext && typeof userContext[0] !== "undefined"
      ? userContext[0]
      : null;

  let endpoint = `node/answers/${answerId}/${field_name}`;
  let method = "POST";
  let response = await fetchAuthenticatedContent(
    userState.auth,
    userContext[1],
    endpoint,
    method,
    file,
    {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/octet-stream",
      "Content-Disposition": `file; filename="${filename}"`,
    }
  );

  return response;
}
